<template>
    <div :aria-label="t('RANGE_SIDEBAR_SEPERATOR_ARIA_LABEL')" :aria-description="t('RANGE_SIDEBAR_SEPERATOR_ARIA_DESCRIPTION')" @mousedown="startResize" tabindex="0" @keydown="onKeydown" @keyup="onKeyup" data-testing="guacamole-separator"></div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const sidebarWidth = defineModel<string>({ required: true });
const isResizing = defineModel('isResizing', { default: false });
const emits = defineEmits<{
    (name: 'done'): void
}>()

function resizeOnMouseMove(event: MouseEvent)
{
    sidebarWidth.value = `${event.pageX}px`;
}
function stopResize()
{
    isResizing.value = false;
    window.removeEventListener('mousemove', resizeOnMouseMove)
    emits('done');
}
function startResize()
{
    isResizing.value = true;
    window.addEventListener('mouseup', stopResize);
    window.addEventListener('mousemove', resizeOnMouseMove);
}

function onKeydown(event: KeyboardEvent)
{
    let width = parseInt(sidebarWidth.value.match(/-?\d*/)?.[0] || '0')
    width = Math.max(width, 320);
    if (event.key === "ArrowRight" || event.key === "ArrowLeft")
    {
        let incrementAmount =
            event.ctrlKey ? 1
            : event.shiftKey ? 20
            : 10;

        if (event.key === "ArrowLeft")
        {
            width -= incrementAmount;
        }
        else
        {
            width += incrementAmount
        }
    }
    else if (Number.isInteger(parseInt(event.key)))
    {
        width = window.innerWidth * (parseInt(event.key) / 10);
    }
    else
    {
        return;
    }
    sidebarWidth.value = `${width}px`;
}

function onKeyup()
{
    emits('done');
}
</script>
