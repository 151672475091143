<template>
    <cr-breadcrumb :items="breadcrumbs" class="cr-font-bold"/>
    <h1 class="page-title" data-testing="form-layout-title">
        {{ props.title }}
    </h1>
    <p v-if="$slots.form" class="cr-mt3 cr-mb4 cr-caption-darken-1" data-testing="form-layout-instruction">
        {{ t('FORM_LAYOUT_INSTRUCTION') }}
    </p>
    <cr-container class="cr-xs-p4 cr-sm-p6 form-layout-container" data-testing="form-layout-container">
        <cr-row v-if="$slots.top">
            <cr-col xs12 data-testing="form-layout-top">
                <slot name="top" />
            </cr-col>
        </cr-row>
        <cr-form @confirm="onConfirm" ref="form">
            <template v-if="!ready">
                <cr-row>
                    <cr-col xs12 data-testing="form-layout-loading">
                        <cr-loading />
                    </cr-col>
                </cr-row>
            </template>
            <template v-else>
                <cr-row v-if="$slots.form">
                    <cr-col xs12 data-testing="form-layout-form">
                        <slot name="form"/>
                    </cr-col>
                </cr-row>
                <cr-row v-if="'default' in $slots && $slots.default" >
                    <cr-col xs12 data-testing="form-layout-default">
                        FormLayout does not support "default" slot. Use "form" slot instead.
                    </cr-col>
                </cr-row>
                <cr-row v-if="$slots.controls">
                    <cr-col xs12 class="cr-text-right" data-testing="form-layout-controls">
                        <ul class="controls">
                            <slot name="controls" />
                        </ul>
                    </cr-col>
                </cr-row>
                <cr-row v-else>
                    <cr-col xs12 class="cr-text-right cr-mt5" data-testing="form-layout-controls">
                        <cr-button text :disabled="isLoading" @click.prevent="onCancel" data-testing="form-layout-cancel">
                            {{ props.cancelText || t('FORM_LAYOUT_CANCEL_BUTTON') }}
                        </cr-button>
                        <cr-button v-if="confirmText !== false" type="submit" :disabled="confirmDisabled ?? isLoading" class="cr-ml3" @click="onConfirmClicked" data-testing="form-layout-confirm">
                            {{ props.confirmText || t('FORM_LAYOUT_CONFIRM_BUTTON') }}
                        </cr-button>
                    </cr-col>
                </cr-row>
                <cr-row v-if="$slots.bottom">
                    <cr-col xs12 data-testing="form-layout-bottom">
                        <slot name="bottom" />
                    </cr-col>
                </cr-row>
            </template>
        </cr-form>
    </cr-container>
</template>

<style scoped>
.form-layout-container
{
    background-color: white;
    width: unset;
    margin-left: 0px;
}

@media (max-width: 576px)
{
    .form-layout-container {
        margin-left: calc(0rem - var(--cr-main-column-side-padding));
        margin-right: calc(0rem - var(--cr-main-column-side-padding));
        padding-left: var(--cr-main-column-side-padding) !important;
        padding-right: var(--cr-main-column-side-padding) !important;
    }
}

@media (min-width: 1024px) 
{
    /**
     * For larger screens, we add the following margin-right so the layout looks consistent with that of ReadLayout.
     */
    .form-layout-container
    {
        margin-right: var(--cr-right-handside-control-width);
    }
}
ul.controls {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.controls:deep(li > .cr-button),
.controls:deep(li > a > .cr-button)
{
    width: 100%;
    margin-bottom: 0.5rem;
}

</style>

<script setup lang="ts">
import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePageTitle } from '../../composables/usePageTitle';
import { useApiClientStore } from '../../stores/apiClientStore';

const { t } = useI18n();
let { isLoading } = storeToRefs(useApiClientStore());

const props = withDefaults(defineProps<
{
    /**
     * A title for the page.
     */
    title?:string

    /**
     * Breadcrumbs for the page.
     */
    breadcrumbs:IBreadcrumbItem[]

    /**
     * The default confirm button's text.
     * If false, the confirm button is hidden
     */
    confirmText?: string|boolean

    /**
     * Disable the confirm buttons
     * @default isLoading from apiClient store used as default disable value
     */
     confirmDisabled?: boolean

    /**
     * The default cancel button's text.
     */
    cancelText?: string

    /**
     * Whether to form is ready to display.
     * A loading indicator will be displayed while waiting for the form to be ready.
     */
    ready: boolean

    /**
     * A function for custom validation on non-native HTML components.
     */
    customValidation?: () => boolean
}>(), {
    confirmText: undefined,
    confirmDisabled: undefined
});

const emits = defineEmits<{
    (name: 'confirm'|'cancel', ev:Event): void,
    (name: 'validated', result:boolean): void
}>();

usePageTitle(computed(()=>props.breadcrumbs));

const form = ref();

const onConfirm = (ev:Event)=>{
    if (props.customValidation && !props.customValidation())
    {
        return; // custom validation failed, don't confirm
    }
    emits('confirm', ev);
}
const onCancel = (ev:Event)=>emits('cancel', ev);

const onConfirmClicked = async () =>
{
    // This runs on click because these validations need to be run regardless of the result of native HTML element validation.
    const result = props.customValidation?.();

    if (result !== undefined)
    {
        emits('validated', result);
    }
}
</script>