<template>
    <table-layout :title="t('ORGANIZATION_USERS_PAGE_TITLE')" :breadcrumbs="breadcrumbs">
       <template #filter>
            <table-filter-section>
                <table-filter-section-item v-if="!onOrganizationUsersPage">
                    <organization-filter v-model="organizationId" :organizations="allowedOrganizations" required data-testing="organization-users-organization-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <role-filter v-model="filter.roles!" :roles="[UserRole.OrganizationAdmin, UserRole.BillingAdmin, UserRole.CourseAdmin, UserRole.CoursewareAdmin,UserRole.OrganizationInstructor, UserRole.OrganizationStudent, UserRole.OrganizationTA]" data-testing="organization-users-role-filter"/>
                </table-filter-section-item>
                <table-filter-section-item>
                    <to-from-date-filter v-model:model-value="date" v-model:model-modifier="modifier" :disabled="isLoading" :label="t('ORGANIZATION_USERS_LAST_LOGIN_FILTER_LABEL')" data-testing="organization-users-last-login-filter"/>
                </table-filter-section-item>
            </table-filter-section>
       </template>
       <template #table>
            <cr-table :items="page?.items" :headers="headers" actionable @sort="onSort" :loading="isLoading" @suggestedNumberOfItems="onSuggestedNumberOfItems" data-testing="organization-users-table">
                <template v-slot:identityProvider="{value}">
                    <cr-identity-provider-icon :provider="value" />
                </template>
                <template v-slot:billingadmin="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:courseadmin="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationadmin="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationinstructor="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationta="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:organizationstudent="{value}">
                    <cr-icon v-if="value" medium :alt="t('ORGANIZATION_USERS_CHECK_ALT')">bi-check</cr-icon>
                </template>
                <template v-slot:action="{item}">
                    <cr-table-action-item v-if="item.isUser" :item="item" :to="{ name: Route.Profile.name, params: { userId: item.id }}" icon="bi-person-vcard" data-testing="organization-users-table-view-profile-action">
                        {{ t('ORGANIZATION_USERS_VIEW_PROFILE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && !item.isAnonymous && (canUpdateOrganizationUser(organizationId) || canUpdateOrganizationUserRoles(organizationId))" :item="item" @click="onEditUserActionItemClicked" icon="bi-pencil" data-testing="organization-users-table-edit-user-action">
                        {{ t('ORGANIZATION_USERS_EDIT_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canImpersonate('', organizationId)" :item="item" @click="onImpersonateActionItemClicked" icon="bi-person-badge-fill" data-testing="organization-users-table-impersonate-action">
                        {{ t('ORGANIZATION_USERS_IMPERSONATE_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isUser && canDeleteOrganizationUsers(organizationId)" :item="item" @click="onDeleteUserActionItemClicked" icon="bi-trash3" data-testing="organization-users-table-delete-user-action">
                        {{ t('ORGANIZATION_USERS_DELETE_USER_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && item.isPendingApproval && canApproveInvitations" :item="item" @click="onApproveAccountClicked" icon="bi-check" data-testing="organization-users-table-approve-invitation-action">
                        {{ t('ORGANIZATION_USERS_APPROVE_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && item.isPendingApproval && canApproveInvitations" :item="item" @click="onRejectAccountClicked" icon="bi-x" data-testing="organization-users-table-reject-invitation-action">
                        {{ t('ORGANIZATION_USERS_REJECT_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && !item.isPendingApproval && canInviteOrganizationUsers(organizationId)" :item="item" @click="onReInviteActionItemClicked" icon="bi-envelope" data-testing="organization-users-table-resend-invitation-action">
                        {{ t('ORGANIZATION_USERS_RESEND_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && canDeleteOrganizationInvitation(organizationId)" :item="item" @click="onDeleteInvitationActionItemClicked" icon="bi-trash3" data-testing="organization-users-table-delete-invitation-action">
                        {{ t('ORGANIZATION_USERS_DELETE_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                    <cr-table-action-item v-if="item.isInvitation && canInviteOrganizationUsers(organizationId)" :item="item" @click="onEditInvitationActionItemClicked" icon="bi-pencil" data-testing="organization-users-table-edit-invitation-action">
                        {{ t('ORGANIZATION_USERS_EDIT_INVITATION_BUTTON') }}
                    </cr-table-action-item>
                </template>
            </cr-table>
            <account-approved-dialog v-model="showAccountApprovedDialog" @confirm="onAccountApprovedCompletedConfirm" />
            <account-rejected-dialog v-model="showAccountRejectedDialog" @confirm="onAccountRejectedCompletedConfirm" />
       </template>

       <template #pagination>
           <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading" data-testing="organization-users-table-pagination">
                <template #bottom>
                    <cr-items-per-page v-model="selectedItemsPerPage" :options="itemsPerPageOptions" data-testing="organization-users-items-per-page"/>
                </template>
           </cr-pagination>
       </template>

       <template #controls>
            <table-control-item v-if="canInviteOrganizationUsers(organizationId)">
                <cr-button :to="inviteUserTo" outlined data-testing="organization-users-invite-users-button">
                        <cr-icon>bi-person-plus-fill</cr-icon>
                        {{ t('ORGANIZATION_USERS_INVITE_USERS') }}
                </cr-button>
            </table-control-item>
            <table-control-item v-if="canViewOrganizationUsers(organizationId)">
                <cr-button :disabled="isExportProcessing" @click="onExportUsersClicked" outlined data-testing="organization-users-export-users-button">
                        <cr-icon>bi-download</cr-icon>
                        {{ t('ORGANIZATION_USERS_EXPORT_USERS') }}
                </cr-button>
            </table-control-item>
            <delete-organization-user-dialog v-model="showDeleteUserDialog" :user="selectedItem" :organization-id="organizationId" @confirm="onDialogConfirmed"/>
            <delete-organization-invitation-dialog v-model="showDeleteInvitationDialog" :invitation="selectedItem" @confirm="onDialogConfirmed"/>
            <resend-organization-invitation-dialog v-model="showReinviteDialog" :invitation="selectedItem" @confirm="onDialogConfirmed"/>
            <edit-organization-invitation-dialog v-model="showEditInvitationDialog" :invitation="selectedItem" :organization-id="organizationId" @confirm="onDialogConfirmed"/>
            <edit-organization-user-dialog v-model="showEditUserDialog" :user="selectedItem" :organization-id="organizationId" @confirm="onDialogConfirmed"/>

       </template>
   </table-layout>
</template>


<script setup lang="ts">
import { ApiPageResponse, IApiPageResponse, SortOrder } from '@cyber-range/cyber-range-api-client';
import { OrganizationUserFilter, OrganizationUserSortBy, UserRole, OrganizationUserTargetType } from '@cyber-range/cyber-range-api-user-client';
import { BreadcrumbItem, ITableHeaderItem, TableHeaderItem } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import { onMounted, ref, computed, toRaw, watch, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../routers/route';
import { useApiClientStore } from '../../stores/apiClientStore';
import TableLayout from '../layouts/TableLayout.vue';
import TableFilterSection from '../layouts/sections/TableFilterSection.vue'
import TableFilterSectionItem from '../layouts/sections/TableFilterSectionItem.vue'
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useQueryFilter } from '../../composables/useQueryFilter';
import RoleFilter from '../filters/RoleFilter.vue';
import { useUserStore } from '../../stores/userStore';
import OrganizationFilter from '../filters/OrganizationFilter.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useEnum } from '../../composables/useEnum';
import IOrganizationUserRoleView from '../../interfaces/iOrganizationUserRoleView';
import OrganizationUserRoleView from '../../entities/organizationUserRoleView';
import DeleteOrganizationUserDialog from './dialogs/DeleteOrganizationUserDialog.vue';
import DeleteOrganizationInvitationDialog from './dialogs/DeleteOrganizationInvitationDialog.vue';
import ResendOrganizationInvitationDialog from './dialogs/ResendOrganizationInvitationDialog.vue';
import EditOrganizationInvitationDialog from './dialogs/EditOrganizationInvitationDialog.vue';
import EditOrganizationUserDialog from './dialogs/EditOrganizationUserDialog.vue';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import TableControlItem from '../layouts/sections/TableControlItem.vue';
import {IOrganizationUsersPageFilter} from '../../interfaces/iOrganizationUsersPageFilter';
import {OrganizationUsersPageFilter} from '../../entities/OrganizationUsersPageFilter';
import {stringify} from 'csv-stringify/sync'
import { useDownload } from '../../composables/useDownload';
import { useAccountApprovals } from '../../composables/useAccountApprovals';
import AccountApprovedDialog from '../accountApprovals/dialogs/AccountApprovedDialog.vue';
import AccountRejectedDialog from '../accountApprovals/dialogs/AccountRejectedDialog.vue';
import { useTableLayoutPagination } from '../../composables/useTableLayoutPagination';
import { useTableSelectItemsPerPage } from '../../composables/useTableSelectItemsPerPage';
import ToFromDateFilter from '../filters/ToFromDateFilter.vue';
import { DateModifier } from '../../interfaces/DateModifier';
import { useCalendar } from '../../composables/useCalendar';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canInviteOrganizationUsers, canViewOrganizationUsers, canDeleteOrganizationUsers, canDeleteOrganizationInvitation, canUpdateOrganizationUser, canUpdateOrganizationUserRoles, canImpersonate} = useAuthorizationStore();
const { fetchOrganizations, fetchOrganizationNameAndLogo } = useOrganizationStore();
const { organizations } = storeToRefs(useOrganizationStore());

const allowedOrganizations = computed(() => organizations.value.filter(org => canViewOrganizationUsers(org.id)));

const router = useRouter();
const onOrganizationUsersPage = router.currentRoute.value.name === Route.OrganizationUsers.name;
const breadcrumbs = computed(()=> {
    if (onOrganizationUsersPage)
    {
        return [
            new BreadcrumbItem(Route.Organizations),
            new BreadcrumbItem({...Route.Organization, text: selectedOrganization.value?.name, params: {organizationId: props.organizationId}}),
            new BreadcrumbItem({...Route.OrganizationUsers, params: {organizationId: props.organizationId}})
        ]
    }
    else
    {
        return [
            new BreadcrumbItem(Route.Users)
        ]
    }
});

const inviteUserTo = computed<RouteLocationRaw>(()=> {
    return onOrganizationUsersPage
        ? { name: Route.InviteOrganizationUser.name, params: {organizationId: organizationId.value} }
        : { name: Route.InviteUser.name, state: { organizationId: organizationId.value } };
})

const props = defineProps<
{
   organizationId?:string
}>();

const selectedItem = ref<IOrganizationUserRoleView>();
const showDeleteUserDialog = ref<boolean>(false);
const showReinviteDialog = ref<boolean>(false);
const showDeleteInvitationDialog = ref<boolean>(false);
const showEditInvitationDialog = ref<boolean>(false);
const showEditUserDialog = ref<boolean>(false);

const refresh = async (data:Partial<IOrganizationUsersPageFilter> = {}) =>
{
    if (!organizationId.value)
    {
        // Don't try to actually fetch until 
        return new ApiPageResponse({items: []});
    }
    const orgUsersPage = await useUserStore().listOrganizationUsers(organizationId.value, new OrganizationUserFilter({...toRaw(filter), ...data}));
    
    orgUsersPage.items = orgUsersPage?.items?.map(orgUser => new OrganizationUserRoleView(orgUser));
    return orgUsersPage as IApiPageResponse<IOrganizationUserRoleView>;
};

const {
    filter,
    onLoadFirstPage,
    onLoadNextPage,
    onLoadPreviousPage,
    // onSort, We have custom logic that handles onSort because there is a more complex mapping with headers
    page
} = useTableLayoutPagination(useQueryFilter(OrganizationUsersPageFilter, {}, {arrayProperties: ['roles']}), refresh, {clearTokenKeys: ['organizationId', 'limit', 'lastLoginAfter', 'lastLoginBefore']});


const headerName = (enumKey: OrganizationUserSortBy) => useEnum().toDisplayEnumName(OrganizationUserSortBy, enumKey);
const headers = [
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Name), key: 'name', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Email), key: 'email', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.BillingAdmin), key: 'billingadmin', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.CourseAdmin), key: 'courseadmin', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.OrganizationAdmin), key: 'organizationadmin', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Instructor), key: 'organizationinstructor', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.TA), key: 'organizationta', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.Student), key: 'organizationstudent', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.IdentityProvider), key: 'identityProvider', sortable: true, align: 'center'}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.LastLoginWith), key: 'lastLoginWith', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.BusinessUnit), key: 'displayedBusinessUnit', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.LastLoginDate), key: 'lastLogin', sortable: true}),
    new TableHeaderItem({ text: headerName(OrganizationUserSortBy.PendingSince), key: 'pendingSince', sortable: true, formatter: useCalendar().toHumanDate}),
];
// These properties sortby keys don't match the properties that are on the OrganizationUserRoleView
const customSortbyHeaderKeyMap: Partial<Record<OrganizationUserSortBy, string>> = {
    [OrganizationUserSortBy.Instructor]: "organizationinstructor",
    [OrganizationUserSortBy.TA]: "organizationta",
    [OrganizationUserSortBy.Student]: "organizationstudent",
    [OrganizationUserSortBy.BusinessUnit]: "displayedBusinessUnit"
};
const headerKeyToFind = filter.sortBy && customSortbyHeaderKeyMap[filter.sortBy] || filter.sortBy;
const sortbyHeader = headers.find(h => h.sortable && h.key.toLowerCase() === headerKeyToFind?.toLowerCase());
if (sortbyHeader)
{
    sortbyHeader.sortOrder = filter.sortOrder;
}

const {
    selectedItemsPerPage,
    itemsPerPageOptions,
    initializeItemsPerPage,
    onSuggestedNumberOfItems
} = useTableSelectItemsPerPage(filter);

const organizationId = ref<string>(props.organizationId || filter.organizationId || '');
const selectedOrganization = computed(() => allowedOrganizations.value.filter(org => org.id === organizationId.value)[0]);
let mountComplete = false;

onMounted(async ()=>
{
    await fetchOrganizations();

    let filterUpdated = false;
    let filterStartedWithLimit = !!filter.limit;

    initializeItemsPerPage();

    if (onOrganizationUsersPage)
    {
        await fetchOrganizationNameAndLogo(props.organizationId);
    }
    else
    {
        if (!organizationId.value)
        {
            organizationId.value = allowedOrganizations.value[0]?.id;
            filter.organizationId = organizationId.value;
            filterUpdated = true;
        }
        await fetchOrganizationNameAndLogo(organizations.value);
    }

    if (filterStartedWithLimit && !filterUpdated)
    {
        // filter started with a value, and another fetch hasn't been triggered, fetch now
        page.value = await refresh();
    }

    mountComplete = true;
});

watch(organizationId, async ()=>
{
    // Dont react to the changes from onMounted, but any time after that
    if (mountComplete)
    {
        filter.organizationId = organizationId.value
    }
});

const onSort = async (header:ITableHeaderItem) =>
{
    const sortOrder = header.sortOrder as SortOrder;
    const sortKey = header.text.replaceAll(" ", "");
    const sortBy = sortKey in OrganizationUserSortBy ? OrganizationUserSortBy[sortKey as keyof typeof OrganizationUserSortBy] : sortKey.toLowerCase() as OrganizationUserSortBy;
    Object.assign(filter, {sortOrder, sortBy, token: ""});
}

const onDeleteUserActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showDeleteUserDialog.value = true;
}

const onReInviteActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showReinviteDialog.value = true;
}

const onDeleteInvitationActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showDeleteInvitationDialog.value = true;
}

const onEditInvitationActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showEditInvitationDialog.value = true;
}

const onEditUserActionItemClicked = (item:IOrganizationUserRoleView) =>
{
    selectedItem.value = item;
    showEditUserDialog.value = true;
}

const onImpersonateActionItemClicked = async (item:IOrganizationUserRoleView) =>
{
    if ((await useAuthenticationStore().impersonate(item.id)))
    {
        router.push(Route.Home);
    }
}

const onDialogConfirmed = async () =>
{
    page.value = await refresh();
}

const isExportProcessing = ref(false);
async function onExportUsersClicked()
{
    isExportProcessing.value = true
    try
    {
        const users = (await useUserStore().listAllOrganizationUsers(organizationId.value, new OrganizationUserFilter({...toRaw(filter) }))).map(orgUser => new OrganizationUserRoleView(orgUser));
        const data = stringify(users, {
            columns: headers.map(h => ({ key: h.key, header: h.text })),
            header: true,
            cast: {boolean: (v: boolean) => v.toString()},
        });
        useDownload().downloadFromCsv(data, 'exported_users');
    }
    finally
    {
        isExportProcessing.value = false;
    }
}

const {
    canApproveInvitations,
    showAccountApprovedDialog,
    onApproveAccountClicked,
    showAccountRejectedDialog,
    onRejectAccountClicked
} = useAccountApprovals(organizationId);
const onAccountApprovedCompletedConfirm = async () => page.value = await refresh();
const onAccountRejectedCompletedConfirm = async () => page.value = await refresh();

// lastLogin Filter
const date = ref<string>(filter.lastLoginAfter || filter.lastLoginBefore || "");
const modifier = ref<DateModifier|undefined>(filter.lastLoginAfter ? DateModifier.OnOrAfter : DateModifier.OnOrBefore);
const lastLoginAfter = computed(()=>date.value && modifier.value === DateModifier.OnOrAfter ? date.value : '');
const lastLoginBefore = computed(()=>date.value && modifier.value === DateModifier.OnOrBefore ? date.value : '');
const lastLogin = computed(()=>({ lastLoginAfter: lastLoginAfter.value, lastLoginBefore: lastLoginBefore.value }));

watch(lastLogin, () =>
{
    Object.assign(filter, lastLogin.value);
})
</script>