<template>
    <form-section v-if="canUpdateOrganizationUser(organizationId)" :label="t('ORGANIZATION_USER_FORM_INFO_SECTION')" data-testing="organization-user-form-info-section" class="cr-mb5">
        <cr-text-field v-model="modelValue.name" :label="t('ORGANIZATION_USER_FORM_NAME')" :disabled="isLoading" required :max-length="100" id="organization-user-form-name" data-testing="organization-user-form-name" class="cr-mt3"/>
        <cr-text-field v-model="modelValue.email" :label="t('ORGANIZATION_USER_FORM_EMAIL')" type="email" :disabled="isLoading" required :max-length="256" id="organization-user-form-email" data-testing="organization-user-form-email" class="cr-mt3"/>
    </form-section>
    <form-section v-if="canUpdateOrganizationUserRoles(organizationId)" :label="t('ORGANIZATION_USER_FORM_ROLE_SECTION')" data-testing="organization-user-form-role-section" class="cr-mb5">
        <role-picker v-model="modelValue.roles" :roles="availableRoles" data-testing="organization-user-edit-form-role-picker"/>
    </form-section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import FormSection from '../../layouts/sections/FormSection.vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import IOrganizationUserRoleView from '../../../interfaces/iOrganizationUserRoleView';
import RolePicker from '../../pickers/RolePicker.vue';

const props = defineProps<{
    organizationId:string
}>();
const modelValue = defineModel<IOrganizationUserRoleView>({ required: true });

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canUpdateOrganizationUser, canUpdateOrganizationUserRoles, canInviteOrganizationAdmin, canInviteBillingAdmin, canInviteCourseAdmin, canInviteOrganizationInstructor, canInviteCoursewareAdmin, canInviteOrganizationStudent, canInviteOrganizationTa } = useAuthorizationStore();

const availableRoles = computed(()=>
{
    let results = [];

    if(canInviteOrganizationAdmin(props.organizationId))
    {
        results.push(UserRole.OrganizationAdmin);
    }
    if(canInviteBillingAdmin(props.organizationId))
    {
        results.push(UserRole.BillingAdmin);
    }
    if(canInviteCourseAdmin(props.organizationId))
    {
        results.push(UserRole.CourseAdmin);
    }
    if(canInviteOrganizationInstructor(props.organizationId))
    {
        results.push(UserRole.OrganizationInstructor);
    }
    if(canInviteCoursewareAdmin(props.organizationId))
    {
        results.push(UserRole.CoursewareAdmin);
    }

    if(modelValue.value.isInvitation)
    {
        return results; // Invitations have a smaller subset of role options
    }

    if(canInviteOrganizationStudent(props.organizationId))
    {
        results.push(UserRole.OrganizationStudent);
    }
    if(canInviteOrganizationTa(props.organizationId))
    {
        results.push(UserRole.OrganizationTA);
    }
    
    return results;
});
</script>