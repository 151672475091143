<template>
    <cr-confirmation-dialog id="editOrganizationUserDialog" :title="t('ORGANIZATION_USERS_EDIT_USER_DIALOG_TITLE')" @confirm="onConfirmed" @shown="onShown" :disabled="isLoading" :form-instruction="true" data-testing="edit-organization-user-dialog">
        <organization-user-form v-model="entry" :organization-id="organizationId" :key="key"/>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import { storeToRefs } from 'pinia';
import IOrganizationUserRoleView from '../../../interfaces/iOrganizationUserRoleView';
import { useUserStore } from '../../../stores/userStore';
import OrganizationUserForm from './OrganizationUserForm.vue';
import OrganizationUserRoleView from '../../../entities/organizationUserRoleView';
import { ref, watch } from 'vue';
import { User } from '@cyber-range/cyber-range-api-user-client';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const entry = ref<IOrganizationUserRoleView>(new OrganizationUserRoleView({}));
const key = ref<number>(0);


const props = defineProps<
{
    organizationId: string;
    user?: IOrganizationUserRoleView;
}>();


const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const populateData = ()=>
{
    if(props.user)
    {
        entry.value.id = props.user.id;
        entry.value.name = props.user.name;
        entry.value.email = props.user.email;
        entry.value.roles = props.user.roles;
        entry.value.targetType = props.user.targetType;
    }
};

watch(()=>props.user, populateData, {immediate:true});

const onConfirmed = async ()=>
{
    if (!props.user) return;

    let updatedUser = false;
    
    if (props.user.roles?.toString() !== entry.value.roles?.toString())
    {
        await useUserStore().updateOrganizationUserRoles(entry.value.id, props.organizationId, entry.value.roles);
        updatedUser = true;
    }

    if (props.user.name !== entry.value.name || props.user.email !== entry.value.email)
    {
        const payload = User.fromJson({id: entry.value.id, name: entry.value.name, email: entry.value.email});
        await useUserStore().update(payload);
        updatedUser = true;
    }

    if(updatedUser)
    {
        useAnnouncementStore().announce(t('ORGANIZATION_USERS_EDIT_USER_DIALOG_CONFIRMATION_ANNOUNCEMENT'));
    }

    emit('confirm');
}

const onShown = async ()=>
{
    key.value++;
    populateData();
};
</script>