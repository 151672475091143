import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { IEndDates, ISubscription, ISubscriptionFilter, Products } from '@cyber-range/cyber-range-api-subscription-client';

export const useSubscriptionStore = defineStore('subscriptionStore', 
{
    state: () => 
    ({
        _organizationSubscribeProducts: new Map<string, string[]>(),
        _organizationSubscribeProductsPromises: new Map<string, Promise<string[]>>(),
        _organizationSubscriptions: new Map<string, ISubscription[]>(),
    }),

    getters:
    {
        isSubscribedTo: (state) => (organizationId: string, product: Products) =>
        {
            const products = state._organizationSubscribeProducts.get(organizationId) || [];
            return products.includes(product);
        },
        organizationSubscriptions: (state) => (organizationId: string) =>
        {
            const subscriptions = state._organizationSubscriptions.get(organizationId) || [];
            return subscriptions;
        }
    },

    actions: 
    {
        async getOrganizationSubscriptionEndDates(organizationId:string): Promise<IEndDates>
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            return subscriptionApiClient.getOrganizationSubscriptionEndDates(organizationId);
        },

        async getOrganizationSubscribedProducts(organizationId: string): Promise<string[]>
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            return subscriptionApiClient.getOrganizationSubscribedProducts(organizationId);
        },

        async fetchOrganizationSubscribedProducts(organizationId: string): Promise<string[]>
        {
            if (!this._organizationSubscribeProducts.has(organizationId))
            {
                if (this._organizationSubscribeProductsPromises.has(organizationId))
                {
                    return await this._organizationSubscribeProductsPromises.get(organizationId)! || [];
                }

                let subscriptionApiClient = useApiClientStore().subscriptionApiClient;
                this._organizationSubscribeProductsPromises.set(
                    organizationId,
                    subscriptionApiClient.getOrganizationSubscribedProducts(organizationId)
                );

                this._organizationSubscribeProducts.set(
                    organizationId,
                    await this._organizationSubscribeProductsPromises.get(organizationId)!
                );
            }
            return this._organizationSubscribeProducts.get(organizationId) || [];
        },

        async listSubscriptions(organizationId?: string, filter?: ISubscriptionFilter, options?: {background?: boolean}): Promise<ISubscription[]>
        {
            let subscriptionApiClient = options?.background ? useApiClientStore().backgroundSubscriptionApiClient : useApiClientStore().subscriptionApiClient;
            
            return await subscriptionApiClient.get(organizationId, filter);
        },

        async fetchOrganizationSubscriptions(organizationId: string, filter?: ISubscriptionFilter, options?: {background?: boolean}): Promise<ISubscription[]>
        {
            let subscriptionApiClient = options?.background ? useApiClientStore().backgroundSubscriptionApiClient : useApiClientStore().subscriptionApiClient;

            const subscriptions = await subscriptionApiClient.get(organizationId, filter);
            
            this._organizationSubscriptions.set(organizationId, subscriptions);

            return subscriptions;
        },

        async deleteSubscription(subscriptionId: string)
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            await subscriptionApiClient.delete(subscriptionId);
        },

        async unsubscribeAddon(organizationId: string, subscriptionId: string, addonId: string)
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            await subscriptionApiClient.unsubscribeAddon(organizationId, subscriptionId, addonId);
        },

        async subscribeAddon(organizationId: string, subscriptionId: string, addonId: string)
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            await subscriptionApiClient.subscribeAddon(organizationId, subscriptionId, addonId);
        },

        async renewSubscription(subscriptionId: string, endDate: string)
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            await subscriptionApiClient.renew(subscriptionId, endDate);
        },

        async subscribe(subscription: ISubscription): Promise<string>
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            return subscriptionApiClient.subscribe(subscription);
        },
        
        async unsubscribe(organizationId: string, subscriptionId: string)
        {
            let subscriptionApiClient = useApiClientStore().subscriptionApiClient;

            await subscriptionApiClient.unsubscribe(organizationId, subscriptionId);
        }
    }
})