<template>
    <cr-confirmation-dialog :title="t('COURSE_USERS_EDIT_USER_DIALOG_TITLE')" @shown="onShown" @confirm="onConfirmed" :disabled="isLoading" :form-instruction="true" data-testing="edit-user-dialog">
        <course-user-form v-model="entry" :courseId="courseId" :organizationId="organizationId"/>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import { useRosterStore } from '../../../stores/rosterStore';
import { storeToRefs } from 'pinia';
import { User, UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useUserStore } from '../../../stores/userStore';
import { IRosterEntry, RosterEntry } from '@cyber-range/cyber-range-api-roster-client';
import CourseUserForm from './CourseUserForm.vue';
import { useRawObject } from '../../../composables/useRawObject';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const entry = ref<IRosterEntry>(new RosterEntry());
const key = ref<number>(0);

const props = defineProps<
{
    courseId: string;
    organizationId?: string;
    user?:IRosterEntry
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const populateData = ()=>
{
    if(props.user)
    {
        // isAnonymous is readonly, so re-instantiate entry.value
        entry.value = new RosterEntry(useRawObject(props.user, {only: ['id', 'name', 'email', 'role', 'isAnonymous']}));
    }
};

watch(()=>props.user, populateData, {immediate:true});

const onConfirmed = async ()=>
{
    if(!props.user) return;    

    let updatedUser = false;
    let clearCacheRequired  = false;

    if(props.user?.name !== entry.value.name || props.user?.email !== entry.value.email)
    {
        let data = new User();
        data.id = entry.value.id;
        data.name = entry.value.name;
        data.email = entry.value.email;
        
        await useUserStore().update(data);

        updatedUser = true;
        clearCacheRequired = true;
    }

    if(props.user?.role !== entry.value.role)
    {
        await useUserStore().updateCourseUserRole(props.courseId, props.user?.id, <UserRole> entry.value.role);

        updatedUser = true;
        clearCacheRequired = true;
    }

    if(updatedUser)
    {
        useAnnouncementStore().announce(t('COURSE_USERS_EDIT_USER_DIALOG_CONFIRMATION_ANNOUNCEMENT'));
    }

    if(clearCacheRequired)
    {
        useRosterStore().clearCache();
    }

    emit('confirm');
}

const onShown = async ()=>
{
    populateData();
};
</script>