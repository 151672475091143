<template>
    <cr-row>
        <cr-col xs12 sm6 md6 lg4>
            <form-section :label="t('ORGANIZATION_FORM_LOGO_SECTION')">
                <cr-image-picker v-if="canEditOrganizationLogo(organization.id)" v-model="logoFile" :src="organization?.logo" :alt="logoAlt" @change="onImagePickerChange" class="cr-mb3 cr-mt3" data-testing="organization-form-logo-section-image-picker"/>
                <cr-image v-else="organization?.logo" :src="organization?.logo" :alt="logoAlt" min-height="10rem" max-height="18rem" width="100%" cover="true" class="cr-p4" data-testing="organization-form-logo-section-logo-image"/>
            </form-section>
        </cr-col>
        <cr-col xs12 sm6 md6 lg8>
            <form-section :label="t('ORGANIZATION_FORM_ORGANIZATION_SECTION')" data-testing="organization-form-organization-section" class="cr-mb5">
                <cr-text-field v-model="organization.name" :label="t('ORGANIZATION_FORM_ORGANIZATION_SECTION_ORGANIZATION_NAME')" :disabled="isLoading || !canEditOrganizationName(organization.id)" required :min-length="1" :max-length="256" data-testing="organization-form-organization-name-input" class="cr-mb2 cr-pt2"/>
                <cr-textarea v-model="organization.description" :label="t('ORGANIZATION_FORM_ORGANIZATION_SECTION_ORGANIZATION_DESCRIPTION')" :disabled="isLoading" required :rows="7" :max-length="512" data-testing="organization-form-organization-description-input"/>
            </form-section>
        </cr-col>
    </cr-row>
    <form-section :label="t('ORGANIZATION_FORM_CONTACT_SECTION')" data-testing="organization-form-contact-section" class="cr-mb5">
        <cr-row>
            <cr-col>
                <cr-text-field v-model="organization.contact.firstName" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_FIRST_NAME')" :disabled="isLoading" required :min-length="1" :max-length="127" data-testing="organization-form-contact-first-name-input" class="cr-mb2 cr-pt2"/>
            </cr-col>
            <cr-col>
                <cr-text-field v-model="organization.contact.lastName" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_LAST_NAME')" :disabled="isLoading" required :min-length="1" :max-length="127" data-testing="organization-form-contact-last-name-input" class="cr-mb2 cr-pt2"/>
            </cr-col>
        </cr-row>
        <cr-row>
            <cr-col>
                <cr-text-field type="email" v-model="organization.contact.email" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_EMAIL')" :disabled="isLoading" required :max-length="256" data-testing="organization-form-contact-email-input" class="cr-mb2"/>
            </cr-col>
            <cr-col>
                <cr-text-field type="tel" v-model="organization.contact.phone" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_PHONE')" :disabled="isLoading" required :min-length="10" :max-length="20" data-testing="organization-form-contact-phone-input" class="cr-mb2"/>
            </cr-col>
        </cr-row>
        <cr-text-field v-model="organization.contact.addressLine1" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_ADDRESS_LINE_1')" :disabled="isLoading" required :min-length="1" :max-length="127" data-testing="organization-form-contact-address-line-1-input" class="cr-mb2"/>
        <cr-text-field v-model="organization.contact.city" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_CITY')" :disabled="isLoading" required :min-length="1" :max-length="127" data-testing="organization-form-contact-city-input" class="cr-mb2"/>
        <cr-text-field v-model="organization.contact.state" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_STATE')" :disabled="isLoading" required :min-length="2" :max-length="3"  data-testing="organization-form-contact-state-input" class="cr-mb2"/>
        <cr-text-field v-model="organization.contact.zip" :label="t('ORGANIZATION_FORM_CONTACT_SECTION_ZIP')" :disabled="isLoading" required :min-length="4" :max-length="20" data-testing="organization-form-contact-zip-input" class="cr-mb2"/>
    </form-section>
    <organization-limits-form-section v-if="organization.id" v-model="limits" :organization-id="organization.id" />
    <form-section v-if="canViewAdvancedOptions" collapsible :label="t('ORGANIZATION_FORM_ADVANCED_SECTION')" data-testing="organization-form-advanced-section">
        <cr-select v-if="canSetOrganizationParent(organization.id)" v-model="organization.parentId" :label="t('ORGANIZATION_FORM_ADVANCED_SECTION_ORGANIZATION_PARENT')" :disabled="isLoading" :items="parentOrganizations" data-testing="organization-form-contact-parent-organization-select" class="cr-mb2 cr-pt2"/>
        <cr-text-field v-if="canEditOrganizationAlternateId(organization.id) " v-model="alternateIdsString" :label="t('ORGANIZATION_FORM_ADVANCED_SECTION_ORGANIZATION_ALTERNATE_IDS')" :disabled="isLoading" :max-length="400" data-testing="organization-form-organization-alternate-ids-input" class="cr-mb2"/>
        <cr-text-field v-if="canEditOrganizationCode(organization.id) " v-model="organization.code" :label="t('ORGANIZATION_FORM_ADVANCED_SECTION_ORGANIZATION_CODE')" :disabled="isLoading" :min-length="3" :max-length="100" data-testing="organization-form-organization-code-input" class="cr-mb2"/>
        <template v-if="canManageOrganizationCustomAttributes(organization.id)">
            <cr-text-field v-for="customAttribute of customAttributeEnumValues" v-model="customAttributes[customAttribute].value" :label="customAttributes[customAttribute].label" :max-length="100" :data-testing="`organization-form-${customAttribute}-field`" class="cr-mb2"/>
            <cr-select v-for="internalAttribute of internalAttributeEnumValues" v-model="internalCustomAttributes[internalAttribute].value" :items="internalAttributeSelectItems" :label="internalCustomAttributes[internalAttribute].label" :data-testing="`organization-form-${internalAttribute}-select`" class="cr-mb2"/>
        </template>
    </form-section>
</template>

<script setup lang="ts">
import { ICustomOrganizationLimits, IOrganization } from '@cyber-range/cyber-range-api-organization-client';
import { storeToRefs } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../stores/apiClientStore';
import FormSection from '../layouts/sections/FormSection.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useOrganizationCustomAttributes } from '../../composables/useOrganizationCustomAttributes';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import OrganizationLimitsFormSection from './OrganizationLimitsFormSection.vue';

let props = defineProps<{
    modelValue:IOrganization,
}>();
const limits = defineModel<Partial<ICustomOrganizationLimits>>('limits');

const emit = defineEmits<{
    (e:'update:modelValue', organization:IOrganization): void,
    (e:'update:logoFile', logoFile:File | undefined): void,
}>();

const { t } = useI18n();

const { canEditOrganizationCode, canEditOrganizationAlternateId, canSetOrganizationParent, canEditOrganizationName, canEditOrganizationLogo, canManageOrganizationCustomAttributes } = useAuthorizationStore();
const canViewAdvancedOptions = computed(()=>
{
    return canEditOrganizationCode(organization.id) || canEditOrganizationAlternateId(organization.id) || canSetOrganizationParent(organization.id) || canManageOrganizationCustomAttributes(organization.id); 
})

const logoAlt = computed(() =>
{
    return organization?.name ? t('ORGANIZATION_FORM_LOGO_ALT_TEXT', {organizationName: organization?.name}) : t('ORGANIZATION_FORM_LOGO_DEFAULT_ALT_TEXT');
})

let { parentOrganizations } = storeToRefs(useOrganizationStore());
let organization = reactive(props.modelValue);
let logoFile = ref<File|undefined>();
let logoChanged = false;
let alternateIdsString = ref("");


// custom attributes
const formLoaded = ref<boolean>(false);
let {
    loadAttributes,
    customAttributeEnumValues,
    customAttributes,
    internalAttributeEnumValues,
    internalAttributeSelectItems,
    internalCustomAttributes,
    attributeValues,
    setOrganizationAttributes
} = useOrganizationCustomAttributes();

watch(()=>attributeValues.value, ()=>
{
    if(formLoaded.value === true)
    {
        setOrganizationAttributes(organization);
    }
}, { immediate: true });

const { isLoading } = storeToRefs(useApiClientStore());

watch(()=>({...organization}), ()=>
{
    emit('update:modelValue', organization);
});

watch(()=>alternateIdsString.value, ()=>
{
    organization.alternateIds = alternateIdsString.value.split(',').map(id => id.trim()).filter(id => !!id);
})

const onImagePickerChange = () =>
{
    if (!logoChanged && logoFile.value === undefined) // image picker cleared before selecting an image
    {
        emit('update:logoFile', undefined);
    }
}

watch(() => logoFile.value, () =>
{
    emit('update:logoFile', logoFile.value);
    logoChanged = true;
});

const loadForm = async ()=>
{
    loadAttributes(organization);
    alternateIdsString.value = organization?.alternateIds?.sort()?.join(',') || '';
    parentOrganizations.value = parentOrganizations.value.filter(org => org.id !== organization.id);
    formLoaded.value = true;
}
loadForm();
</script>