<template>
    <cr-breadcrumb :items="breadcrumbs" class="cr-font-bold"/>
    <h1 class="page-title" data-testing="read-layout-title">
        {{ props.title }}
    </h1>
    <cr-container v-responsive="responsiveBreakpoints" class="cr-mt5 cr-p0 read-layout-container-container">

        <cr-row v-if="'default' in $slots && $slots.default">
            <cr-col class="cr-mr3 cr-mb5 read-lauout-default-slot" data-testing="read-layout-default">
                ReadLayout does not support "default" slot. Use "top" and/or "bottom" slots instead.
            </cr-col>
        </cr-row>

        <div class="read-layout-container">

            <div v-if="$slots.top || $slots.controls" v-reverse="!responsiveBreakpoints.sm" class="read-layout-row">
                <div v-if="$slots.top || $slots.controls" class="read-layout-top cr-p6">
                    <cr-loading v-if="!isTopReady" data-testing="read-layout-loading"/>
                    <div v-else class="read-layout-top-slot" data-testing="read-layout-top">
                        <slot name="top" />
                    </div>
                </div>

                <div v-if="isTopReady && $slots.controls" class="read-layout-top-controls" data-testing="read-layout-controls">
                    <layout-controls>
                        <slot name="controls" />
                    </layout-controls>
                </div>
            </div>

            <div v-if="$slots.bottom || $slots.bottomControls" v-reverse="!responsiveBreakpoints.sm" class="read-layout-row">
                <div v-if="$slots.bottom" data-testing="read-layout-bottom">
                    <cr-loading v-if="!isBottomReady" class="cr-p6" data-testing="read-layout-loading"/>
                    <slot v-else name="bottom" />
                </div>

                <div v-if="isBottomReady && $slots.bottomControls" class="read-layout-bottom-controls" data-testing="read-layout-bottom-controls">
                    <layout-controls>
                        <slot name="bottomControls" />
                    </layout-controls>
                </div>
            </div>

            <div v-if="$slots.list || $slots.listControls" v-reverse="!responsiveBreakpoints.sm" class="read-layout-row">
                <div v-if="$slots.list" class="read-layout-list" data-testing="read-layout-list">
                    <cr-loading v-if="!isListReady" class="cr-p6" data-testing="read-layout-loading"/>
                    <slot v-else name="list" />
                </div>

                <div v-if="isListReady && $slots.listControls" class="read-layout-list-controls" data-testing="read-layout-list-controls">
                    <layout-controls>
                        <slot name="listControls" />
                    </layout-controls>
                </div>
            </div>

            <div v-if="$slots.pagination" class="cr-mb5 read-layout-pagination" data-testing="read-layout-pagination">
                <slot name="pagination" />
            </div>
        </div>
    </cr-container>
</template>

<style scoped>
.read-layout-container-container {
    container-name: read-layout;
    container-type: inline-size;
}
.read-layout-container {
    display: grid;
    grid-template-columns: minmax(0, auto) minmax(auto, var(--cr-right-handside-control-width));
    column-gap: .75rem;
    row-gap: 2rem;
}
.read-layout-row {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: subgrid;
}

.read-layout-container:not(:has(ul.controls)),
.read-layout-container:has(ul.controls:empty) {
    grid-template-columns: minmax(0, auto);
    column-gap: 0;
}
.read-layout-top {
    background-color: white;
}

.read-layout-bottom {
    overflow-x: auto;
}
.read-layout-list {
    overflow-x: auto;
}

@container read-layout (max-width: 576px)
{
    .read-layout-container {
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;
        row-gap: .75rem;
    }
}
@media (max-width: 576px)
{
    .read-layout-top {
        margin-left: calc(0rem - var(--cr-main-column-side-padding));
        margin-right: calc(0rem - var(--cr-main-column-side-padding));
        padding-left: var(--cr-main-column-side-padding) !important;
        padding-right: var(--cr-main-column-side-padding) !important;
    }
}
</style>

<script setup lang="ts">
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed } from '@vue/reactivity';
import { usePageTitle } from '../../composables/usePageTitle';
import { reactive } from 'vue';
import LayoutControls from './LayoutControls.vue';

const props = defineProps<
{
    /**
     * A title for the page.
     */
    title?:string

    /**
     * Breadcrumbs for the page.
     */
    breadcrumbs:BreadcrumbItem[]

    /**
     * Whether to form is ready to display.
     * A loading indicator will be displayed while waiting for the form to be ready.
     */
    ready: boolean|{ top?:boolean, bottom?: boolean, list?: boolean }
}>()

const allReady = computed(() => 
{
    if (typeof props.ready === 'boolean')
    {
        return props.ready;
    }
    return (props.ready.top ?? true) && (props.ready.bottom ?? true) && (props.ready.list ?? true);
})
const isTopReady = computed(() => typeof props.ready === 'boolean' ? props.ready : props.ready.top ?? allReady.value);
const isBottomReady = computed(() => typeof props.ready === 'boolean' ? props.ready : props.ready.bottom ?? allReady.value);
const isListReady = computed(() => typeof props.ready === 'boolean' ? props.ready : props.ready.list ?? allReady.value);

const responsiveBreakpoints = reactive({ sm: false });

usePageTitle(computed(()=>props.breadcrumbs));
</script>
