export default {
    en: {
        //Courses lising
        COURSES_MY_COURSES: 'My Courses',
        COURSES_COURSES: 'Courses',
        COURSES_COURSE_CREATE_COURSE_BUTTON: 'Create a Course',
        COURSES_COURSE_CREATE_COURSE_PAGE_TITLE: 'Create a Course',
        COURSES_COURSE_CREATE_MY_COURSE_PAGE_TITLE: 'Create My Course',
        COURSES_COURSE_CREATE_COURSE_NO_INSTRUCTOR_SET_WARNING: "No instructors have been set for the course. Would you like to continue anyway?",
        COURSES_CREATE_COST_CHANGED_DIALOG_TITLE: 'Cost Estimate Changed',
        COURSES_CREATE_COST_CHANGED_DIALOG_CONFIRM_TEXT: 'Ok',
        COURSES_CREATE_COST_CHANGED_DIALOG_MESSAGE: 'Your cost was estimated at ${expectedCost}, but will actually cost ${actualCost}',
        COURSES_COURSE_APPROVE_COURSE_BUTTON: 'Approve Course',
        COURSES_COURSE_REJECT_COURSE_BUTTON: 'Reject Course',
        COURSES_COURSE_EDIT_COURSE_BUTTON: 'Edit Course',
        COURSES_COURSE_CREATE_EXERCISE_GROUP_BUTTON: 'Create Exercise Environment',
        COURSES_COURSE_COURSE_USERS_BUTTON: 'Manage Course Users',
        COURSES_COURSE_VIEW_LOGS_BUTTON: 'View Logs',
        COURSES_COURSE_DELETE_COURSE_BUTTON: 'Delete Course',
        COURSES_COURSE_COURSE_APPLICATIONS_BUTTON: 'Connected Apps',
        COURSES_COURSE_DELETE_COURSE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{courseName}</b>?',
        COURSES_COURSE_ERROR: 'This course is in an error state.',
        COURSES_COURSES_NO_ITEMS: 'No courses found.',
        COURSES_COURSES_NO_ITEMS_MINE: 'You have no courses.',
        COURSES_INSTRUCTOR_FILTER: 'Instructors',
        COURSES_DATE_FILTER: 'Expiration',
        COURSES_SORTING: 'Sort by',

        COURSES_COURSE_INSTRUCTORS: 'Instructors',
        COURSES_COURSE_NO_INSTRUCTORS: 'No instructors',
        COURSES_COURSE_NUMBER_OF_ENVIRONMENTS: '{n} exercise environments | {n} exercise environment | {n} exercise environments',
        COURSES_COURSE_NUMBER_OF_ENROLLMENTS: 'Enrollments',
        COURSES_COURSE_USERS: '{n} enrollments | {n} enrollment | {n} enrollments',
        COURSES_COURSE_EXPIRATION: 'Expiration',
        COURSES_COURSE_EXPIRES_ON: 'Expires on',
        COURSES_COURSE_EXPIRED_ON: 'Expired {expiredDuration} ago',
        COURSES_COURSE_NEVER_EXPIRE: 'Never expire',
        COURSES_COURSE_YOUR_ROLE: 'Your Role',

        //Course Invitation
        COURSE_INVITATION_SUBJECT: "[[course.name]] - Invitation from [[organization.name]]",
        COURSE_INVITATION_BODY_TEXT: "Dear [[user.name]],↵↵↵↵↵Welcome to [[course.name]]. Here is your unique invitation link:↵[[invitation.link]] Please follow this link to get started!↵↵↵↵↵Sincerely,↵↵[[organization.name]]",
        COURSE_INVITATION_BODY_HTML: "<p>Dear [[user.name]],</p><p><br></p><p>Welcome to [[course.name]]. Here is your unique invitation link: [[invitation.link]] Please follow this link to get started!</p><p><br></p><p>Sincerely,</p><p>[[organization.name]]</p>",
    
        COURSE_AWAITING_APPROVAL_COURSE_TEXT: "This course is waiting for your approval.",
        COURSE_REVIEW_COURSE_TEXT: "Please wait while this course is being reviewed by a course administrator.",
        COURSE_EXPIRATION: "Expiration",
        COURSE_EXPIRATION_NEVER_EXPIRE: "Never expires",
        COURSE_CREATION: "Creation",
        COURSE_REQUESTED_BY: "Requested by",
        COURSE_DENIED_BY: "Denied by",
        COURSE_BUSINESS_UNIT: "Business Unit",
        COURSE_USER_ROLE: "Your Role",
        COURSE_DESCRIPTION: "Description",
        COURSE_JUSTIFICATION: "Justification",
        COURSE_STATUS: "Status",
        COURSE_ENROLLMENTS: "Enrollments",
        COURSE_DIRECT_PAY_LABEL: 'Payment',
        COURSE_DIRECT_PAY_TEXT: 'Direct Pay',

        //Course form
        COURSE_FORM_ORGANIZATION_SECTION: 'Organization',
        COURSE_FORM_ORGANIZATION_SECTION_ORGANIZATION: 'Organization',
        COURSE_FORM_ORGANIZATION_SECTION_BUSINESS_UNIT: 'Business Unit',
        COURSE_FORM_ORGANIZATION_SECTION_ERROR_NO_ORGANIZATIONS: 'No organizations are available.  Please create one first.',
        COURSE_FORM_ORGANIZATION_SECTION_ERROR_NO_BUSINESS_UNITS: 'This organization does not have a business unit. Please create one first.',

        COURSE_FORM_COURSE_INFO_SECTION: 'Course Information',
        COURSE_FORM_COURSE_INFO_SECTION_COURSE_NAME: 'Course name',
        COURSE_FORM_COURSE_INFO_SECTION_COURSE_DESCRIPTION: 'Course description',
        COURSE_FORM_COURSE_INFO_SECTION_COURSE_START_DATE: 'Access Start Date (UTC)',
        COURSE_FORM_COURSE_INFO_SECTION_COURSE_END_DATE: 'Access End Date (UTC)',
        COURSE_FORM_COURSE_INFO_SECTION_UPDATE_ENVIRONMENT_END_DATES: 'Apply this end date to all exercise environments in this course.',
        COURSE_FORM_COURSE_INFO_SECTION_UPDATE_ENVIRONMENT_END_DATES_ANNOUNCEMENT: 'A checkbox to update exercise environment end dates is now available.',

        COURSE_FORM_JUSTIFICATION_SECTION: 'Tell us how this course relates to Cyber Security',
        COURSE_FORM_JUSTIFICATION_SECTION_EXPLAINATION: 'Briefly describe how this proposed course supports cybersecurity education at your school. If appropriate, include specific Verso Curriculum code, CAE Knowledge Units, or NIST/NICE knowledge, skills, or abilities covered in the course.',
        COURSE_FORM_JUSTIFICATION_SECTION_JUSTIFICATION: 'Justification',
        COURSE_FORM_INSTRUCTOR_SECTION: 'Instructor',

        COURSE_FORM_PAYMENT_SECTION: 'Payment',
        COURSE_FORM_PAYMENT_SECTION_TEXT: 'Currently, {organizationName} is responsible for the cost of this course. However, you can allow users to pay for access to this course. With User Direct Pay, users, instead of {organizationName}, pay the costs associated with this course.',
        COURSE_FORM_DIRECT_PAY_HELP_LINK_TEXT: 'What is user direct pay?',
        COURSE_FORM_DIRECT_PAY_ENABLED_LABEL: 'User Direct Pay',
        COURSE_FORM_DIRECT_PAY_COURSE_COST_MESSAGE: 'Each user will pay <b>{cost}</b> to access this course.',
        COURSE_FORM_DIRECT_PAY_ACKNOWLEDGEMENT: 'I understand that this operation cannot be undone. The access begin date and the course expiration date configured above cannot be changed. All user exercises will be deleted and unavailable to all users including instructors after the course expiration date.',
        COURSE_FORM_DIRECT_PAY_COST_LOADING: 'Calculating cost...',

        EDIT_COURSE_UPDATE_ENVIRONMENT_JOB_DIALOG_TITLE: 'Update Exercise Environments',
        EDIT_COURSE_UPDATE_ENVIRONMENT_JOB_ARIA_LABEL: "Update Exercise Environments Progress",

        //Course Users
        COURSE_USERS_PAGE_TITLE: 'Users',
        COURSE_USERS_NAME: 'Name',
        COURSE_USERS_EMAIL: 'Email',
        COURSE_USERS_ROLE: 'Role',
        COURSE_USERS_TEAM_NAME: 'Team',
        COURSE_USERS_LOGIN_WITH: 'Login With',
        COURSE_USERS_LAST_LOGIN_WITH: 'Last Login With',
        COURSE_USERS_PENDING_SINCE: 'Pending Since',
        COURSE_USERS_ANONYMOUS_INVITATION_CODE_DISABLED_TOOLTIP: "Manage student invitation code for this course. Your organization has been configured to protect the privacy of students and TAs. You can only add students or TA's to this course using anonymous credentials.",
        COURSE_USERS_ANONYMOUS_USER_INVITATION_DISABLED_TOOLTIP: "Invite a user to this course. Your organization has been configured to protect the privacy of students and TAs. You can only add students or TA's to this course using anonymous credentials.",
        COURSE_USERS_BULK_DELETE_BUTTON_LABEL: "Delete",
        COURSE_USERS_BULK_DELETE_JOB_DIALOG_TITLE: "Delete Users",
        COURSE_USERS_BULK_DELETE_DELETE_DIALOG_MESSAGE: "Are you sure you want to delete <b>{itemCount} item</b>?|Are you sure you want to delete <b>{itemCount} items</b>?",
        COURSE_USERS_BULK_DELETE_ARIA_LABEL: "User Deletion Progress",

        //Course Users - User
        COURSE_USERS_DELETE_USER_BUTTON: 'Delete',
        COURSE_USERS_DELETE_USER_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{name}</b> from this course?',
        COURSE_USERS_DELETE_USER_DIALOG_DELETE_TEAM_MESSAGE: 'Also, remove team "{name}"?',
        COURSE_USERS_RENAME_USER_TEAM_BUTTON: 'Rename Team',
        COURSE_USERS_MOVE_USER_BUTTON: 'Move Team',
        COURSE_USERS_MOVE_USER_DIALOG_TITLE: 'Move Team',
        COURSE_USERS_MOVE_USER_DIALOG_MESSAGE: 'Move <b>{userName}</b> from <b>{teamName}</b> to',
        COURSE_USERS_MOVE_USER_DIALOG_TEAMS_DROPDOWN: 'Team',
        COURSE_USERS_VIEW_PROFILE_BUTTON: 'View Profile',
        COURSE_USERS_EDIT_USER_BUTTON: 'Edit User',
        COURSE_USERS_EDIT_USER_DIALOG_TITLE: 'Edit User',
        COURSE_USERS_EDIT_USER_DIALOG_CONFIRMATION_ANNOUNCEMENT: 'User updated successfully',
        COURSE_USER_FORM_INFO_SECTION: 'Information',
        COURSE_USER_FORM_NAME: 'Name',
        COURSE_USER_FORM_EMAIL: 'Email',
        COURSE_USER_FORM_ROLE_SECTION: 'Role',
        COURSE_USER_FORM_ROLE: 'Role',
        COURSE_USERS_RESET_PASSWORD_BUTTON: 'Reset Password',
        COURSE_USERS_IMPERSONATE_BUTTON: 'Impersonate',


        //Course Users - Team
        COURSE_USERS_CREATE_TEAM_BUTTON: 'Create a Team', 
        COURSE_USERS_CREATE_TEAM_DIALOG_TITLE: 'Create a Team', 
        COURSE_USERS_CREATE_TEAM_DIALOG_LABEL: 'Team Name', 
        COURSE_USERS_DELETE_TEAM_BUTTON: 'Delete',
        COURSE_USERS_DELETE_TEAM_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{teamName}</b>?',
        COURSE_USERS_RENAME_TEAM_BUTTON: 'Rename',
        COURSE_USERS_RENAME_TEAM_DIALOG_TITLE: 'Rename Team',
        COURSE_USERS_RENAME_TEAM_DIALOG_MESSAGE: 'Rename <b>{oldTeamName}</b> to',
        COURSE_USERS_RENAME_TEAM_DIALOG_LABEL: 'Team name',
        COURSE_USERS_EMPTY_TEAM_ALERT: 'There is an empty team in this course. All resources associated with empty teams are subject to removal.|There are empty teams in this course. All resources associated with empty teams are subject to removal.',

        //Course Users - Invitation
        COURSE_USERS_RESEND_INVITATION_BUTTON: 'Resend',
        COURSE_USERS_RESEND_INVITATION_DIALOG_TITLE: 'Resend an Invitation',
        COURSE_USERS_RESEND_INVITATION_DIALOG_MESSAGE: 'Are you sure you want to resend an invitation to <b>{name}</b>?',
        COURSE_USERS_DELETE_INVITATION_BUTTON: 'Delete',
        COURSE_USERS_DELETE_INVITATION_DIALOG_TITLE: 'Delete an Invitation',
        COURSE_USERS_DELETE_INVITATION_DIALOG_MESSAGE: "Are you sure you want to delete <b>{name}</b> from this course?",
        COURSE_USERS_CREATE_INVITATION_CODE_BUTTON: 'Invitation Code',

        //Course Users - Invitation Code
        COURSE_USERS_INVITATION_CODE_PAGE_TITLE: "Invitation Code",
        COURSE_USERS_INVITATION_CODE_DESCRIPTION_LABEL: "Description",
        COURSE_USERS_INVITATION_CODE_CREATE_INVITATION_CODE_BUTTON: "Create Invitation Code",
        COURSE_USERS_INVITATION_CODE_EDIT_RESERVED_ENROLLMENTS_BUTTON: "Edit Reserved Enrollments",
        COURSE_USERS_INVITATION_CODE_DELETE_INVITATION_CODE_BUTTON: "Delete Invitation Code",
        COURSE_USERS_INVITATION_CODE_ACTIVE_INVITATION_CODE: "none",
        COURSE_USERS_INVITATION_CODE_NO_INVITATION_CODE: "No Active Invitation Code",
        COURSE_USERS_INVITATION_CODE_DESCRIPTION: "Invitation codes offer a convenient solution for inviting users, particularly in workshop scenarios where there is no prior knowledge of participant details or when email filtering is strict within a school. These codes can be generated and shared with the class, enabling them to sign up for the course using the provided code. This approach simplifies the enrollment process, ensuring accessibility for all participants.",
        COURSE_USERS_INVITATION_CODE_LABEL: "Code",
        COURSE_USERS_INVITATION_CODE_CLIPBOARD_COPY: 'Copy the invitation code to clipboard',
        COURSE_USERS_INVITATION_CODE_DETAILS: "Details",
        COURSE_USERS_INVITATION_CODE_EXPIRATION_LABEL: "Active Until",
        COURSE_USERS_INVITATION_CODE_REGISTRATION_LINK_LABEL: "Registration Link",
        COURSE_USERS_INVITATION_CODE_REGISTRATION_LINK_CLIPBOARD_COPY: 'Copy the registration link to clipboard',
        COURSE_USERS_INVITATION_CODE_LEARN_ABOUT_CODES: "Learn about invitation codes",
        COURSE_USERS_INVITATION_CODE_LEARN_ABOUT_SELF_REGISTRATION: "Learn about self registration",
        COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_LABEL: "Reserved Enrollments",
        COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_TITLE: "Edit Reserved Enrollments",
        COURSE_USERS_INVITATION_CODE_CREATED_DIALOG_BODY: "<b>Consider Reserved Enrollments</b> if you are hosting a large course or workshop and wish to expedite students' initial access time.",
        COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_DIALOG_BODY: "This action adds Virtual Machines (VMs) to a pool of available VMs that serves to speed up the preparation of student environments on first usage; the cost of these pool VMs could increase your monthly bill.",
        COURSE_USERS_INVITATION_CODE_RESERVED_ENROLLMENTS_FIELD_LABEL: "Expected total number of users",
        COURSE_USERS_INVITATION_CODE_LEARN_ABOUT_RESERVED_ENROLLMENTS_LABEL: "Learn about reserved enrollments",
        COURSE_USERS_INVITATION_CODE_CREATED_DIALOG_TITLE: "Invitation Code",
        COURSE_USERS_INVITATION_CODE_LOADING_MESSAGE: "Creating invitation code...",
        COURSE_USERS_INVITATION_CODE_CREATED_CONFIRM_TEXT: "OK",
        COURSE_USERS_INVITATION_CODE_CREATED_COPY_CODE_ICON_TITLE: "Copy Invitation Code",
        COURSE_USERS_DELETE_INVITATION_CODE_DIALOG_MESSAGE:"Are you sure you want to delete the invitation code for this course?",

        //Course Users - External Management
        COURSE_USERS_EXTERNAL_MANAGEMENT_ALERT: 'Users in this course are managed through an external application.',
        COURSE_USERS_SYNC_USERS_BUTTON: 'Sync Users',

        //Course Users - Anonymous Credentials
        COURSE_USERS_CREATE_CREDENTIALS_BUTTON: 'Anonymous Credentials',

        // Course Invite Users
        COURSE_USERS_INVITE_USERS_PAGE_TITLE: "Invite Users",
        COURSE_USERS_INVITE_USERS: 'Invite Users',
        COURSE_USERS_INVITE_USERS_IMPORT_SECTION_LABEL: 'Import Multiple Users',
        COURSE_USERS_INVITE_ONE_USER_SECTION_LABEL: 'Invite a User',
        COURSE_USERS_INVITE_USERS_NAME_LABEL: 'Name',
        COURSE_USERS_INVITE_USERS_EMAIL_LABEL: 'Email',    
        COURSE_USERS_INVITE_USERS_ROLE_LABEL: 'Role',    
        COURSE_USERS_IMPORT_FROM_CSV_CARD_TITLE: 'Upload a CSV',
        COURSE_USERS_IMPORT_FROM_CSV_CARD_TEXT: 'Send an invitation to every user in your spreadsheet.',
        COURSE_USERS_IMPORT_FROM_GOOGLE_CLASSTOOM_CARD_TITLE: 'Google Classroom',
        COURSE_USERS_IMPORT_FROM_GOOGLE_CLASSTOOM_CARD_TEXT: 'Send a Cyber Range invitation to every user in your Google Classroom class.',
        COURSE_USERS_IMPORT_FROM_CANVAS_CARD_TITLE: 'Canvas Course',
        COURSE_USERS_IMPORT_FROM_CANVAS_CARD_TEXT: 'Configure a course application to use the Cyber Range in your Canvas course.',
        
        // Course Import Users
        COURSE_USERS_IMPORT_PAGE_TITLE: "Import Users",
        COURSE_USERS_IMPORT_TITLE_CSV: "Import Users - CSV",
        COURSE_USERS_IMPORT_TITLE_CSV_REVIEW: "Import Users - CSV - Review",
        COURSE_USERS_IMPORT_TITLE_GOOGLE_CLASSROOM: "Import Users - Google Classroom",
        COURSE_USERS_IMPORT_TITLE_GOOGLE_CLASSROOM_REVIEW: "Import Users - Google Classroom - Review",
        COURSE_USERS_IMPORT_CSV_REVIEW_BLURB : "Please take a moment to review the table below carefully. Ensure that all the information displayed is accurate. Once you've confirmed that everything is in order, press the 'Import' button to proceed.",
        COURSE_USERS_IMPORT_USERS_BY_CSV_LABEL: "Upload a CSV",
        COURSE_USERS_IMPORT_USERS_BY_GOOGLE_CLASSROOM_LABEL: "from a Google Classroom class",
        COURSE_USERS_IMPORT_BY_CSV_FILE_DOWNLOAD: "To invite users,",
        COURSE_USERS_IMPORT_BY_CSV_FILE_ANCHOR: "download this \".CSV\" template",
        COURSE_USERS_IMPORT_BY_CSV_FILE_MODIFY: "and modify it to contain name, email, and role for each user then upload the file below and click next.",
        COURSE_USERS_IMPORT_USERS_TABLE_LABEL: "Users to import",
        COURSE_USERS_IMPORT_STEP_ONE_CONFIRM_TEXT: "Next",
        COURSE_USERS_IMPORT_STEP_TWO_CONFIRM_TEXT: "Import",
        COURSE_USERS_IMPORT_STEP_TWO_CANCEL_TEXT: "Back",
        COURSE_USERS_IMPORT_CSV_ERROR_INCOMPLETE_NAME: "The selected CSV file contains incomplete data. Name column cannot be blank. Please ensure the format matches the example template such that each row contains a name, email, and role.",
        COURSE_USERS_IMPORT_CSV_ERROR_INCOMPLETE_EMAIL: "The selected CSV file contains incomplete data. Email column cannot be blank. Please ensure the format matches the example template such that each row contains a name, email, and role.",
        COURSE_USERS_IMPORT_CSV_ERROR_INVALID_EMAIL: "Your CSV file contains an invalid email address '{email}'. Please update your CSV file and try again.",
        COURSE_USERS_IMPORT_CSV_ERROR_INVALID_FILE: "The selected file is invalid. Please ensure the format matches the example template and try again.",
        COURSE_USERS_IMPORT_GOOGLE_CLASSROOM_ERROR_ON_FETCH_COURSES: "We are unable to find any Google Classroom classes. Please ensure you select an account associated with a Google Classroom account.",
        COURSE_USERS_IMPORT_GOOGLE_CLASSROOM_ERROR_ON_FETCH_COURSE_STUDENTS: "We are unable to import any students from the selcted course. Please ensure your Google classroom is properly configured and try again.",
        COURSE_USERS_IMPORT_SIGNIN_GOOGLE_CLASSROOM_LABEL: "Sign in with a Google Classroom account.",
        COURSE_USERS_IMPORT_SIGNIN_GOOGLE_CLASSROOM_BLURB: "To invite students from a Google Classroom class, please log into your Google Classroom account. Please keep in mind that the Google account you choose to login with must be associated with a Google Classroom account that contains the students in your school or organization. If an account that is not associated with Google Classroom is used, you will not be able to import or view any students.",
        COURSE_USERS_IMPORT_SELECT_A_GOOGLE_CLASSROOM_DIALOG_TITLE: "Select a Classroom",
        COURSE_USERS_IMPORT_SELECT_A_GOOGLE_CLASSROOM_DIALOG_BODY: "Please select which Google Classroom class you would like to import your students from. You will then be able to validate the users who will be invited.",
        COURSE_USERS_IMPORT_SELECT_A_GOOGLE_CLASSROOM_SELECT_LABEL: "Google Classroom",
        COURSE_USERS_IMPORT_USERS_IMPORTED_CONFIRMATION_DIALOG_TITLE: "Users Imported",
        COURSE_USERS_IMPORT_USERS_IMPORTED_CONFIRMATION_DIALOG_BODY: "{count} user has been imported successfully. | {count} users have been imported successfully.",
        COURSE_USERS_IMPORT_USERS_IMPORTED_CONFIRMATION_DIALOG_CONFIRM: "Done",

        // Course Users - Credential
        COURSE_USERS_DELETE_CREDENTIAL_BUTTON: "Delete",
        COURSE_USERS_DELETE_CREDENTIAL_DIALOG_TITLE: 'Delete a Credential',
        COURSE_USERS_DELETE_CREDENTIAL_DIALOG_MESSAGE: "Are you sure you want to delete <b>{name}</b> from this course?",
        COURSE_USERS_RESET_PASSWORD_DIALOG_TITLE: 'Reset Password',
        COURSE_USERS_RESET_PASSWORD_TEXT: "Are you sure you want to reset a password for <b>{name}</b>?",
        COURSE_USERS_RESET_PASSWORD_NEW_PASSWORD_TEXT: "The new password for <b>{name}</b> is ",
        COURSE_USERS_RESET_PASSWORD_DIALOG_RESET_BUTTON: 'Confirm',
        COURSE_USERS_RESET_PASSWORD_DIALOG_CONFIRM: 'OK',

        // Course Applications
        COURSE_APPLICATIONS_PAGE_TITLE: "Connected Applications",
        COURSE_APPLICATIONS_DELETE_APPLICATION_BUTTON: "Delete",
        COURSE_APPLICATIONS_APPLICATION_ID: "Application ID",
        COURSE_APPLICATIONS_TYPE: "Type",
        COURSE_APPLICATIONS_ORGANIZATION_APPLICATION_ID: "Organization Application ID",
        COURSE_APPLICATIONS_CREATED: "Created",
        COURSE_APPLICATIONS_TYPE_LTI: "LTI 1.3",
        COURSE_APPLICATIONS_DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{applicationId}</b>?',

        // Course Invite Users - Canvas Integration
        COURSE_USERS_CANVAS_INTEGRATION_SELECTION_INSTRUCTIONS_TEXT: 'Please select your Canvas integration from the following dropdown for more information on how to proceed.',
        COURSE_USERS_CANVAS_INTEGRATION_SELECTION_LABEL: 'Canvas Application',
        COURSE_USERS_CANVAS_INTEGRATION_TITLE: "Canvas Integration",
        COURSE_USERS_CANVAS_INTEGRATION_NOT_FOUND_BUTTON: "I don't see my Canvas integration",
        COURSE_USERS_CONFIGURING_CANVAS_FORM_HELP_TEXT: 'Please provide the following information from your Canvas account so that support can set up a Canvas integration. For more information about the required information, check out',
        COURSE_USERS_CONFIGURING_CANVAS_FORM_LINK_TEXT: 'setting up the cyber range',
        COURSE_USERS_LINKING_CANVAS_SELECTED_APPLICATION: 'Your organization has already been configured to work with <b>{name}</b>.',
        COURSE_USERS_LINKING_CANVAS_HELP_TEXT: 'You can link your cyber range course with Canvas by following',
        COURSE_USERS_LINKING_CANVAS_LINK_TEXT: 'how to link Canvas',
        COURSE_USERS_CONFIGURING_CANVAS_HELP_TEXT: "Please contact your organization's administrators to set up a Cyber Range integration with your organization's Canvas using LTI before proceeding further. For more information see",
        COURSE_USERS_CONFIGURING_CANVAS_LINK_TEXT: 'Configuring LTI Integration in Canvas',
        COURSE_USERS_CANVAS_INTEGRATION_CANCEL_LABEL: 'Cancel',
        COURSE_USERS_CANVAS_INTEGRATION_BACK_LABEL: 'Back',
        COURSE_USERS_CANVAS_INTEGRATION_CONFIRM_LABEL: 'Submit',
        COURSE_USERS_CANVAS_INTEGRATION_SUPPORT_MESSAGE_SUBJECT: 'Canvas Integration Request',
        COURSE_USERS_CANVAS_INTEGRATION_SUPPORT_MESSAGE_BODY: 'An instructor would like to setup a Canvas integration.\nOrganization Application Information:\n',

        // Course Approvals
        COURSE_APPROVED_DIALOG_TITLE: "Approved",
        COURSE_APPROVED_DIALOG_MESSAGE: "The course has been approved, and an email has been sent to inform the instructor.",
        COURSE_APPROVED_DIALOG_CONFIRM_TEXT: "OK",
        COURSE_REJECTED_DIALOG_TITLE: "Rejected",
        COURSE_REJECTED_DIALOG_MESSAGE: "The course has been rejected, and an email has been sent to inform the instructor.",
        COURSE_REJECTED_DIALOG_CONFIRM_TEXT: "OK",
        COURSE_APPROVALS_PAGE_TITLE: "Course Approvals",
        COURSE_APPROVALS_VIEW: "View",
        COURSE_APPROVALS_APPROVE: "Approve",
        COURSE_APPROVALS_REJECT: "Reject",

        //Create a course hint
        COURSES_HINTS_NO_COURSES: "You have no courses.",
        COURSES_HINTS_REQUEST_COURSE: "Let's request one",
        COURSES_HINTS_USE_CREATE_COURSE_BUTTON: "using the 'Create a Course' button.",

        //Add an exercise environment hints
        COURSE_HINTS_NO_ENVIRONMENTS: "This course has no exercise environments.",
        COURSE_HINTS_ADD_ENVIRONMENT: "Let's add one using the",
        COURSE_HINTS_USE_ADD_ENVIRONMENT_BUTTON: "'Add Exercise Environment' button.",

        //Add an instructor hints
        COURSE_HINTS_NO_INSTRUCTORS: "This course has no instructors.",
        COURSE_HINTS_ADD_INSTRUCTOR: "Let's add one using the",
        COURSE_HINTS_USE_MANAGE_USERS_BUTTON: "'Manage Course Users' button.",
        COURSE_HINTS_USE_INVITE_USERS_BUTTON: "'Invite Users' button.",
    }
};