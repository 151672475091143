<template>
    <cr-col xs12 sm12 md12 lg12 xl4>
        <cr-image v-if="exerciseGroup?.metadata?.logo" :src="exerciseGroup.metadata.logo" alt="" class="cr-p4" min-height="10rem" max-height="18rem" width="100%" cover="true" />
    </cr-col>

    <cr-col xs12 sm12 md12 lg12 xl8>
        <read-section :label="t('EXERCISE_GROUP_ABOUT_TEXT')" data-testing="exercise-group-about-text">
            <p class="exercise-group-info-section">{{ exerciseGroup?.description }}</p>
        </read-section>

        <read-detail-section class="cr-mt5">

            <read-detail-section-item :label="t('EXERCISE_GROUP_AVAILABILITY')" data-testing="exercise-group-availability">
                {{ availability }}
            </read-detail-section-item>

            <read-detail-section-item v-if="availabilityWarning" label="" data-testing="exercise-group-availability-warning">
                <cr-warning-text :message="availabilityWarning" />
            </read-detail-section-item>

            <read-detail-section-item v-if="creation" :label="t('EXERCISE_GROUP_CREATION')" data-testing="exercise-group-creation">
                {{ creation }}
            </read-detail-section-item>

            <read-detail-section-item v-if="persistence" :label="t('EXERCISE_GROUP_SESSION')" data-testing="exercise-group-session">
                {{ persistence }}
            </read-detail-section-item>

            <read-detail-section-item v-if="exercise && durationRemainingIsLow" label="">
                <exercise-low-duration-warning :exercise="exercise"/>
            </read-detail-section-item>

            <read-detail-section-item v-if="networks" :label="t('EXERCISE_GROUP_NETWORKS')" data-testing="exercise-group-networks">
                {{ networks }}
            </read-detail-section-item>

            <read-detail-section-item v-if="accessibleFeatures.length > 0" :label="t('EXERCISE_GROUP_ACCESSIBILITY')" data-testing="exercise-group-accessibility">
                <div v-for="feature in accessibleFeatures" :key="feature.id">
                    {{ feature.name }}: {{feature.description}}
                </div>
            </read-detail-section-item>

            <read-detail-section-item v-if="canViewCourseResources && catalogEntry?.instructions?.length" :label="t('EXERCISE_GROUP_RESOURCES')" data-testing="exercise-group-resources">
                <catalog-resource-links :catalog-entry="catalogEntry"/>
            </read-detail-section-item>
            <!-- Credentials -->

            <read-detail-section-item v-if="canSeeInterTeamConnectivityControls" :label="t('EXERCISE_GROUP_INTER_TEAM_CONNECTVITY_STATUS_LABEL')" data-testing="exercise-group-inter-team-connectivity">
                {{ interTeamConnectvityStatus }}
            </read-detail-section-item>

            <read-detail-section-item v-if="exercise" :label="t('EXERCISE_GROUP_MY_ENVIRONMENT_STATUS')" data-testing="exercise-group-exercise-status">
                <exercise-status-chip :status="exercise.status" :loading="exerciseLoading" />
            </read-detail-section-item>

            <read-detail-section-item v-if="exerciseIsInErrorState" label="">
                <cr-warning-text :message="errorStateWarning" />
            </read-detail-section-item>
        </read-detail-section>
        <read-detail-section v-if="exercise?.dnsRecords?.length" :label="t('EXERCISE_GROUP_MY_ENVIRONMENT_DNS_RECORDS')" data-testing="my-exercise-dns-record-section">
            <read-detail-section-item v-for="record of exercise.dnsRecords" :label="record.name" data-testing="my-exercise-dns-record">
                <cr-selectable-text :value="record.value"/>
                <cr-clipboard-copy :value="record.value" :copy-label="t('EXERCISE_GROUP_MY_ENVIRONMENT_DNS_COPY_LABEL', {recordName: record.name})"/>
            </read-detail-section-item>
        </read-detail-section>
    </cr-col>
</template>

<style scoped>
.exercise-group-info-section {
    white-space: pre-wrap;
}
</style>

<script setup lang="ts">
import { ICatalogEntry } from '@cyber-range/cyber-range-api-catalog-client';
import { FeatureId, FeatureStatus, FeatureType, IExerciseGroup, Feature } from '@cyber-range/cyber-range-api-user-client';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCalendar } from '../../composables/useCalendar';
import { useAnnouncementStore } from '@cyber-range/cyber-range-lib-ui';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useCatalogStore } from '../../stores/catalogStore';
import ReadSection from '../layouts/sections/ReadSection.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import { ExerciseStatus, IExercise } from '@cyber-range/cyber-range-api-exercise-client';
import ExerciseStatusChip from './ExerciseStatusChip.vue';
import Config from '../../config';
import { useAuthenticationStore } from '../../stores/authenticationStore';
import ExerciseLowDurationWarning from './ExerciseLowDurationWarning.vue'
import { useExerciseDurationWarnings } from '../../composables/useExerciseDurationWarnings';
import CatalogResourceLinks from './CatalogResourceLinks.vue';
import { useEnum } from '../../composables/useEnum';

const { toHuman } = useCalendar();

const props = defineProps<
{
    exerciseGroup: IExerciseGroup;
    exercise?: IExercise;
    exerciseLoading?: boolean
}>();

const { t } = useI18n();
const authorizationStore = useAuthorizationStore();

const canReset = computed(()=> authorizationStore.canResetExercise(props.exercise?.courseId as string, props.exercise?.organizationId as string));

const catalogEntry = ref<ICatalogEntry>();

const availabilityWarning = computed(() => {
    const now = new Date();
    if (props.exerciseGroup.startTime && now < new Date(props.exerciseGroup.startTime))
    {
        return t('EXERCISE_GROUP_NOT_AVAILABLE');
    }
    else if (props.exerciseGroup.endTime && now > new Date(props.exerciseGroup.endTime))
    {
        return t('EXERCISE_GROUP_EXPIRED');
    }
});
const availability = computed(() => {
    const startTime = toHuman(props.exerciseGroup.startTime);
    const endTime = toHuman(props.exerciseGroup.endTime);
    return `${startTime} - ${endTime}`;
});
const creation = computed(() => {
    if (authorizationStore.canViewCourseTimestamp(props.exerciseGroup.courseId, props.exerciseGroup.organizationId))
    {
        return toHuman(props.exerciseGroup.createdTimestamp);
    }
});

const persistence = computed(() => {
    try
    {
        return props.exerciseGroup.isPersistent ? t('EXERCISE_GROUP_SESSION_PERSISTENT') : t('EXERCISE_GROUP_SESSION_NON_PERSISTENT');
    }
    catch{}
});

const networks = computed(() => {
    const network = catalogEntry.value?.metadata?.tags?.networks?.[0];
    if (network === 'shared')
    {
        return t('EXERCISE_GROUP_NETWORKS_SHARED');
    }
    else if (network === 'isolated')
    {
        return t('EXERCISE_GROUP_NETWORKS_ISOLATED');
    }
});

const { durationRemainingIsLow } = useExerciseDurationWarnings(computed(()=>props.exercise));

const exerciseIsInErrorState = computed(():boolean=>
{
    return props.exercise?.status === ExerciseStatus.ERROR
});

const errorStateWarning = computed(() =>
{
    if (canReset.value)
    {
        return t('EXERCISE_GROUP_EXERCISE_NEEDS_A_REINSTALL_MESSAGE');
    }
    else
    {
        return t('EXERCISE_GROUP_ASK_FOR_EXERCISE_REINSTALL_ASSISTANCE_MESSAGE');
    }
});

const releaseFocusFeature = new Feature({id: 'release-focus-feature-id', type: FeatureType.Accessibility, name: t('EXERCISE_GROUP_ACCESSIBILITY_RELEASE_FOCUS_NAME'), description: t('EXERCISE_GROUP_ACCESSIBILITY_RELEASE_FOCUS_DESCRIPTION')});
const accessibleFeatures = computed(() => {
    const baseAccessibleFeatures = props.exerciseGroup.features?.filter( f => f.type === FeatureType.Accessibility ) || [];
    if (props.exerciseGroup.isCtf)
    {
        return baseAccessibleFeatures;
    }
    return [...baseAccessibleFeatures, releaseFocusFeature];
})

const canViewCourseResources = computed (() => authorizationStore.canViewCourseResources(props.exerciseGroup.courseId, props.exerciseGroup.organizationId));

watch(() => props.exerciseGroup.catalogId, async () => {
    if (!props.exerciseGroup.catalogId || !canViewCourseResources.value) return;
    catalogEntry.value = await useCatalogStore().fetch(props.exerciseGroup.catalogId);
}, { immediate: true });

watch(() => [props.exerciseLoading, props.exercise?.status], ([newIsLoading, newStatus], [oldIsLoading, oldStatus]) =>
{
    let status: string;
    if (newIsLoading !== oldIsLoading && newIsLoading === true)
    {
        status = 'pending';
    }
    else if (oldStatus && newStatus && newStatus !== oldStatus)
    {
        status = (newStatus as ExerciseStatus).replace('pending_', '');
    }
    else
    {
        return;
    }

    useAnnouncementStore().announce(t('EXERCISE_GROUP_MY_ENVIRONMENT_STATUS_ANNOUNCEMENT', { status }));
});

const { toDisplayEnumName } = useEnum()
const canSeeInterTeamConnectivityControls = computed(() => !!props.exerciseGroup?.features?.find(f=>f.id === FeatureId.InterTeamConnectivityControl));
const interTeamConnectvityStatus = computed(() =>
{
    const status = props.exerciseGroup?.features?.find(f=>f.id === FeatureId.InterTeamConnectivityControl)?.status
    switch (status)
    {
        case FeatureStatus.PendingDisabled:
        case FeatureStatus.Enabled:
            return toDisplayEnumName(FeatureStatus, FeatureStatus.Enabled);
        case FeatureStatus.PendingEnabled:
        case FeatureStatus.Disabled:
            return toDisplayEnumName(FeatureStatus, FeatureStatus.Disabled);
    }
})
watch(interTeamConnectvityStatus, (newStatus, oldStatus) =>
{
    if (!oldStatus || !newStatus || oldStatus === newStatus)
    {
        return;
    }

    useAnnouncementStore().announce(t('EXERCISE_GROUP_INTER_TEAM_CONNECTVITY_STATUS_ANNOUNCEMENT', { status: newStatus.toLowerCase() }));
})
</script>
