<template>
    <cr-breadcrumb :items="breadcrumbs" class="cr-font-bold"/>
    <h1 class="page-title" data-testing="list-layout-title">
        {{ props.title }}
    </h1>
    <cr-container v-responsive="responsiveBreakpoints" class="list-layout-container cr-mt5 cr-pl0" data-testing="list-layout-container">
        <div class="list-layout-content-container cr-ml0 cr-pl0">
            <cr-row v-if="!ready">
                <cr-col xs12 data-testing="list-layout-loading">
                    <cr-loading />
                </cr-col>
            </cr-row>
            <template v-else>
                <div v-if="'default' in $slots && $slots.default" class="list-layout-default-slot" data-testing="list-layout-default">
                    ListLayout does not support "default" slot. Use "list" and/or "table" slots instead.
                </div>
                <div v-reverse="!responsiveBreakpoints.sm" class="list-layout-sub-container">
                    <div v-if="$slots.filter" class="list-layout-filter-slot" data-testing="list-layout-filter">
                        <slot name="filter" />
                    </div>
                    <div v-if="$slots.controls" class="controls list-layout-controls-slot" data-testing="list-layout-controls">
                        <layout-controls>
                            <slot name="controls" />
                        </layout-controls>
                    </div>
                </div>

                <div class="cr-mb5 list-layout-content" data-testing="list-layout-content">
                    <div v-if="$slots.list" class="list-layout-list-slot" data-testing="list-layout-list">
                        <slot name="list" />
                    </div>
                    <div v-if="$slots.table" class="list-layout-list-slot" data-testing="list-layout-table">
                        <slot name="table" />
                    </div>
                    <div v-if="$slots.pagination" class="list-layout-pagination-slot cr-ptb4" data-testing="list-layout-pagination">
                        <slot name="pagination" />
                    </div>
                </div>
            </template>
        </div>
    </cr-container>
</template>

<style scoped>
.list-layout-container {
    flex-grow: 1;
    container-name: list-layout;
    container-type: inline-size;
}
.list-layout-content-container {
    display: grid;
    min-height: 100%;
}
.list-layout-sub-container {
    display: grid;
}
.list-layout-filter-slot > :deep(section) {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

.list-layout-content
{
    display: flex;
    flex-direction: column;
    min-width: 0;
}
.list-layout-list-slot {
    flex-grow: 1;
}
@container list-layout (min-width: 577px) {
    .list-layout-content-container {
        grid-template-columns: minmax(0, auto) minmax(auto, var(--cr-right-handside-control-width));
        column-gap: .75rem;
        row-gap: 1rem;
    }
    .list-layout-sub-container {
        grid-template-columns: subgrid;
        grid-template-rows: subgrid;
    }
    .list-layout-content-container:has(.list-layout-filter-slot) {
        grid-template-rows: auto 1fr;
    }
    .list-layout-content-container:not(:has(.list-layout-filter-slot)) {
        grid-template-rows: 1fr;
    }
    .list-layout-content-container:not(:has(ul.controls)),
    .list-layout-content-container:has(ul.controls:empty) {
        grid-template-columns: minmax(0, auto);
        column-gap: 0;
    }
    .list-layout-sub-container {
        grid-column: 1/-1;
        grid-row: 1/-1;
    }
    .list-layout-controls-slot {
        grid-column: -2/-1;
        grid-row: 1/-1;
    }
    .list-layout-filter-slot {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .list-layout-content {
        grid-column: 1/-2;
        grid-row: -2/-1;
    }
}
@container list-layout (max-width: 576px) {
    .list-layout-content-container {
        grid-template-columns: minmax(0, 1fr);
        row-gap: .75rem;
    }
    .list-layout-sub-container {
        row-gap: .75rem;
    }
    .list-layout-filter-slot > :deep( section) {
        flex-direction: column;
    }
    .list-layout-filter-slot > :deep( section .cr-dropdown > .cr-button) {
        width: 100%;
    }
}

</style>

<script setup lang="ts">
import { usePageTitle } from '../../composables/usePageTitle';
import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { computed, reactive } from 'vue';
import LayoutControls from './LayoutControls.vue'

const props = defineProps<
{
    /**
     * A title for the page.
     */
    title?:string

    /**
     * Breadcrumbs for the page.
     */
    breadcrumbs:IBreadcrumbItem[]

    /**
     * Whether the content is ready to display.
     * A loading indicator will be displayed while if the content is not ready.
     */
    ready: boolean
}>()

const responsiveBreakpoints = reactive({ sm: false });

usePageTitle(computed(()=>props.breadcrumbs));
</script>